/* eslint-disable react/jsx-pascal-case */
import { graphql, Link } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { jsx } from 'theme-ui';
import Layout from '../layout';

/** @jsx jsx */

const shortcodes = { Link } // Provide common components here
export default function PostTemplate(props) {
  const { data: { mdx } } = props;
  return (
    <Layout>
      <MDXProvider components={shortcodes}>
        <MDXRenderer>{mdx.body}</MDXRenderer>
      </MDXProvider>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
      }
    }
  }
`